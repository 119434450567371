export default function MoongateLogo({
  color,
  size,
}: {
  color: string;
  size: number;
}) {
  return (
    <svg
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 37C0 57.4344 16.5656 74 37 74C57.4344 74 74 57.4344 74 37C74 16.5652 57.4344 -1.98298e-06 37 -1.98298e-06C16.5656 -1.98298e-06 0 16.5652 0 37V37ZM35.107 69.5271C35.107 70.6429 36.0115 71.5474 37.1273 71.5474C38.2432 71.5474 39.1477 70.6429 39.1477 69.5271C39.1477 68.4113 38.2432 67.5068 37.1273 67.5068C36.0115 67.5068 35.107 68.4113 35.107 69.5271ZM4.60095 37C4.60095 51.1247 13.6401 63.1361 26.249 67.5701C18.0486 63.5801 12.3969 55.168 12.3969 45.4351C12.3969 31.8473 23.4119 20.832 37 20.832C50.5881 20.832 61.6028 31.8473 61.6028 45.4351C61.6028 55.168 55.9514 63.5801 47.751 67.5701C60.3599 63.1361 69.399 51.1247 69.399 37C69.399 19.1063 54.8937 4.60095 37 4.60095C19.1063 4.60095 4.60095 19.1063 4.60095 37ZM27.7275 32.7821C27.7275 37.9737 31.9361 42.1819 37.1273 42.1819C42.3189 42.1819 46.5271 37.9737 46.5271 32.7821C46.5271 27.5909 42.3189 23.3827 37.1273 23.3827C31.9361 23.3827 27.7275 27.5909 27.7275 32.7821ZM30.5391 50.2928C30.5391 53.9314 33.4887 56.8813 37.1273 56.8813C40.7663 56.8813 43.7159 53.9314 43.7159 50.2928C43.7159 46.6541 40.7663 43.7042 37.1273 43.7042C33.4887 43.7042 30.5391 46.6541 30.5391 50.2928ZM33.3504 62.3076C33.3504 64.3937 35.0416 66.0846 37.1273 66.0846C39.2134 66.0846 40.9047 64.3937 40.9047 62.3076C40.9047 60.2216 39.2134 58.5303 37.1273 58.5303C35.0416 58.5303 33.3504 60.2216 33.3504 62.3076Z"
        fill={color}
      />
    </svg>
  );
}

MoongateLogo.defaultProps = {
  size: 50,
  color: "#F0C420",
};
