import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import * as anchor from "@project-serum/anchor";
import { useConnection } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InfiniteScrollingGrid from "../../Components/HighLevelComponents/InfiniteScrollingGrid/InfiniteScrollingGrid";
import Pagination from "../../Components/HighLevelComponents/Pagination/Pagination";
import Socials from "../../Components/HighLevelComponents/Socials/Socials";
import HorizontalFlexContainer from "../../Components/Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import PageWrapper from "../../Components/Layout/PageWrapper/PageWrapper";
import Spacer from "../../Components/Layout/Spacer/Spacer";
import VerticalFlexContainer from "../../Components/Layout/VerticalFlexContainer/VerticalFlexContainer";
import MainText from "../../Components/Text/MainText/MainText";
import { IRarity } from "../../types/Rarity";
import useWindowDimensions, {
  useHotDealsQuery,
  useProjectQuery,
  useRaritiesQuery,
} from "../../utils/hooks";
import styles from "./ProjectRarityHome.module.css";
import { IoSearch } from "react-icons/io5";
import { MdCancel } from "react-icons/md";
import { shortenAddress } from "../../candy-machine";
import { Switch } from "@material-ui/core";
import RarityClassBadge from "../../Components/HighLevelComponents/RarityClassBadge/RarityClassBadge";
import { SmallRarityCard } from "../../Components/HighLevelComponents/SmallRarityCard/SmallRarityCard";
import NFTPrice from "../../Components/HighLevelComponents/NFTPrice/NFTPrice";

export default function ProjectRarityHome({
  connection,
}: {
  connection: anchor.web3.Connection;
}) {
  let params = useParams();
  const { loading, error, data } = useProjectQuery(parseInt(params.projectId!));
  const navigate = useNavigate();
  if (data) {
    const project = data.getProject.project;

    if (!project.is_rarity_enabled) {
      navigate("/not-found");
    }

    return (
      <PageWrapper>
        <VerticalFlexContainer
          height="100%"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          paddingLeft="4%"
          paddingRight="4%"
          flexWrap="wrap"
        >
          <HorizontalFlexContainer
            width="300px"
            height="300px"
            justifyContent="center"
            alignItems="center"
            marginTop="10px"
            marginLeft="10px"
            marginRight="10px"
            marginBottom="10px"
            borderRadius="150px"
            overflow="hidden"
            border="solid white 4px"
          >
            <img src={project.image_url} width="300px" height="300px" />
          </HorizontalFlexContainer>
          <VerticalFlexContainer
            minWidth="300px"
            maxWidth="500px"
            marginTop="10px"
            marginLeft="10px"
            marginRight="10px"
            marginBottom="10px"
            alignItems="center"
          >
            <MainText
              textAlign="center"
              fontSize="30px"
              fontWeight={700}
              color="#F0EFF4"
            >
              {project.name}
            </MainText>
            <HorizontalFlexContainer>
              <Socials
                color="#F0EFF4"
                discord={project.discord}
                twitter={project.twitter}
                website={project.website}
              />
            </HorizontalFlexContainer>
            <Spacer height="10px" />
          </VerticalFlexContainer>
          <HotDeals projectId={parseInt(params.projectId!)} />
          <Spacer height="14px" />
          <Rarities
            project_id={parseInt(params.projectId!)}
            numberOfItems={project.number_of_items!}
          />
          <Spacer height="30px" />
        </VerticalFlexContainer>
      </PageWrapper>
    );
  } else return <></>;
}

function HotDeals({ projectId }: { projectId: number }) {
  const { loading, error, data } = useHotDealsQuery(projectId);
  if (data && data.getHotDeals.length) {
    return (
      <VerticalFlexContainer width="100%">
        <Spacer height="14px" />
        <MainText color="white" fontWeight={800} fontSize={"20px"}>
          🔥 Hot Deals 🔥
        </MainText>
        <Spacer height="12px" />
        <HorizontalFlexContainer flexWrap="wrap" justifyContent="center">
          {data.getHotDeals.map((rarity) => (
            <SmallRarityCard rarity={rarity} />
          ))}
        </HorizontalFlexContainer>
      </VerticalFlexContainer>
    );
  }

  return <></>;
}

function Rarities({
  project_id,
  numberOfItems,
}: {
  project_id: number;
  numberOfItems: number;
}) {
  const [pageSize, setPageSize] = useState(100);
  const totalCount = numberOfItems;
  const [isListed, setIsListed] = useState(false);
  const { loading, error, data, fetchMore } = useRaritiesQuery(
    project_id,
    pageSize,
    0,
    isListed
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [showNoResults, setShowNoResults] = useState(false);

  const { width } = useWindowDimensions();

  const onPageChange = async (n: number | string) => {
    if (typeof n === "number") {
      await fetchMore({
        variables: {
          projectId: project_id,
          limit: pageSize,
          start: pageSize * (n - 1),
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (fetchMoreResult) {
            return {
              ...fetchMoreResult,
            };
          }
          return {
            ...previousResult,
          };
        },
      });
      setCurrentPage(n);
    }
  };

  const onSearch = async () => {
    if (searchString.length > 1) {
      await fetchMore({
        variables: {
          projectId: project_id,
          searchString,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (fetchMoreResult?.getRarities.length === 0) {
            setShowNoResults(true);
          }
          if (fetchMoreResult) {
            return {
              ...fetchMoreResult,
            };
          }
          return {
            ...previousResult,
          };
        },
      });
      setSearchIsActive(true);
    }
  };

  const refetchRarities = async () => {
    await fetchMore({
      variables: {
        projectId: project_id,
        isListed,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (fetchMoreResult?.getRarities.length === 0) {
          setShowNoResults(true);
        }
        if (fetchMoreResult) {
          return {
            ...fetchMoreResult,
          };
        }
        return {
          ...previousResult,
        };
      },
    });
  };

  async function clearSearch() {
    setSearchString("");
    setCurrentPage(1);
    await onPageChange(1);
    setSearchIsActive(false);
    setShowNoResults(false);
  }

  if (data) {
    const rarities = data.getRarities;
    return (
      <VerticalFlexContainer flex={1} width="100%">
        <VerticalFlexContainer alignItems="center" width="100%" flexWrap="wrap">
          <HorizontalFlexContainer>
            <input
              placeholder="Search for token id or name"
              className={styles.searchBar}
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
              value={searchString}
            />
            <Spacer width="8px" />

            <HorizontalFlexContainer
              className={`${styles.searchButton} ${
                searchString.length < 1 ? styles.disabled : ""
              }`}
              onClick={searchIsActive ? clearSearch : onSearch}
            >
              {searchIsActive ? <MdCancel /> : <IoSearch />}
            </HorizontalFlexContainer>
          </HorizontalFlexContainer>
          <Spacer height="24px" />
          <HorizontalFlexContainer>
            {!searchIsActive && !isListed && (
              <Pagination
                onPageChange={onPageChange}
                siblingCount={width < 500 ? 0 : 1}
                currentPage={currentPage}
                pageSize={pageSize}
                totalCount={totalCount}
              />
            )}
          </HorizontalFlexContainer>
          <HorizontalFlexContainer
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <MainText color="#F0EFF4" fontWeight={700}>
              Listed
            </MainText>
            <Switch
              checked={isListed}
              onChange={(e) => {
                setIsListed(e.target.checked);
              }}
              color="primary"
            />
          </HorizontalFlexContainer>
        </VerticalFlexContainer>
        <Spacer height="14px" />
        {showNoResults ? (
          <HorizontalFlexContainer width="100%" justifyContent="center">
            <MainText color="white">
              Our alpaca robots could not find a match to your search. please
              try again with something else!
            </MainText>
          </HorizontalFlexContainer>
        ) : (
          <div className={styles.gridContainer}>
            {rarities.map((rarity, i) => (
              <RarityNFTCard rarityData={rarity} key={i} />
            ))}
          </div>
        )}
        <Spacer height="20px" />
        {!searchIsActive && !isListed && (
          <HorizontalFlexContainer width="100%" justifyContent="center">
            <Pagination
              onPageChange={onPageChange}
              siblingCount={width < 500 ? 0 : 1}
              currentPage={currentPage}
              pageSize={pageSize}
              totalCount={totalCount}
            />
          </HorizontalFlexContainer>
        )}
      </VerticalFlexContainer>
    );
  }

  return <></>;
}

function RarityNFTCard({ rarityData }: { rarityData: IRarity }) {
  const onClick = () => {
    const url = `https://magiceden.io/item-details/${rarityData.mint_id}`;
    window.open(url, "_blank");
  };
  return (
    <VerticalFlexContainer
      className={styles.rarityBaseContainer}
      onClick={rarityData.current_price ? onClick : undefined}
      cursor={rarityData.current_price ? "pointer" : ""}
    >
      {rarityData.rarity_class != undefined && (
        <HorizontalFlexContainer
          justifyContent="flex-end"
          paddingRight="10px"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <RarityClassBadge rarityClass={rarityData.rarity_class} />
        </HorizontalFlexContainer>
      )}
      <img
        style={{ width: "100%", minHeight: "150px" }}
        src={rarityData.image_url}
      ></img>
      <VerticalFlexContainer padding="10px" paddingLeft="18px">
        <MainText fontWeight={800} fontSize="14px">
          {rarityData.name}
        </MainText>
        <HorizontalFlexContainer
          justifyContent="space-between"
          alignItems="center"
        >
          <MainText fontSize="14px">Rank {rarityData.rarity_rank}</MainText>
          {rarityData.current_price && (
            <NFTPrice price={rarityData.current_price} />
          )}
        </HorizontalFlexContainer>
      </VerticalFlexContainer>
    </VerticalFlexContainer>
  );
}
