import React from "react";
import styles from "./MainText.module.css";

const MainText: React.FC<IText> = ({ children, debug, ...props }) => {
  return (
    <p
      className={styles.baseText}
      style={{ ...props, border: debug ? "solid red 1px" : "" }}
    >
      {children}
    </p>
  );
};

export default MainText;
