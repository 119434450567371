import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_HOT_DEALS, GET_PROJECT, GET_RARITIES } from "../graphql";
import { IProjectQuery } from "../types/Project";
import { IHotDeals, IRarityProjects } from "../types/Rarity";

export function useProjectQuery(projectId: number) {
  const { loading, error, data } = useQuery<IProjectQuery>(GET_PROJECT, {
    variables: {
      getProjectId: projectId,
    },
  });

  return { loading, error, data };
}

export function useRaritiesQuery(
  projectId: number,
  pageSize: number,
  offset = 0,
  isListed = false,
  searchString?: string
) {
  const { loading, error, data, fetchMore } = useQuery<IRarityProjects>(
    GET_RARITIES,
    {
      variables: {
        projectId,
        start: offset,
        limit: isListed ? undefined : pageSize,
        searchString,
        isListed,
      },
    }
  );

  return { loading, error, data, fetchMore };
}

export function useHotDealsQuery(projectId: number) {
  const { loading, error, data } = useQuery<IHotDeals>(GET_HOT_DEALS, {
    variables: {
      projectId,
    },
  });

  return { loading, error, data };
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
