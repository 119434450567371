import HorizontalFlexContainer from "../../Layout/HorizontalFlexContainer/HorizontalFlexContainer";

export default function SolanaLogo({ size }: { size: number }) {
  return (
    <HorizontalFlexContainer alignItems="center">
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_321_81)">
          <path
            d="M3.57369 16.7912C3.70645 16.6218 3.889 16.5229 4.08261 16.5229H21.6406C21.9614 16.5229 22.1218 17.017 21.895 17.3064L18.4266 21.7318C18.2938 21.9012 18.1113 22 17.9177 22H0.359706C0.0388612 22 -0.121561 21.5059 0.105243 21.2166L3.57369 16.7912Z"
            fill="url(#paint0_linear_321_81)"
          />
          <path
            d="M3.57369 0.268207C3.71198 0.098813 3.89453 0 4.08261 0H21.6406C21.9614 0 22.1218 0.494065 21.895 0.783446L18.4266 5.20886C18.2938 5.37825 18.1113 5.47706 17.9177 5.47706H0.359706C0.0388612 5.47706 -0.121561 4.983 0.105243 4.69362L3.57369 0.268207Z"
            fill="url(#paint1_linear_321_81)"
          />
          <path
            d="M18.4266 8.4767C18.2938 8.30731 18.1113 8.2085 17.9177 8.2085H0.359706C0.0388612 8.2085 -0.121561 8.70256 0.105243 8.99194L3.57369 13.4174C3.70645 13.5867 3.889 13.6856 4.08261 13.6856H21.6406C21.9614 13.6856 22.1218 13.1915 21.895 12.9021L18.4266 8.4767Z"
            fill="url(#paint2_linear_321_81)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_321_81"
            x1="19.9633"
            y1="-2.64361"
            x2="2.52658"
            y2="23.5325"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00FFA3" />
            <stop offset="1" stopColor="#DC1FFF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_321_81"
            x1="14.65"
            y1="-6.18297"
            x2="-2.78672"
            y2="19.9932"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00FFA3" />
            <stop offset="1" stopColor="#DC1FFF" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_321_81"
            x1="17.2897"
            y1="-4.4246"
            x2="-0.14698"
            y2="21.7515"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00FFA3" />
            <stop offset="1" stopColor="#DC1FFF" />
          </linearGradient>
          <clipPath id="clip0_321_81">
            <rect width="22" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </HorizontalFlexContainer>
  );
}

SolanaLogo.defaultProps = {
  size: 16,
};
