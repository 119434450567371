import { useQuery } from "@apollo/client";
import React from "react";
import { useNavigate } from "react-router-dom";
import FeaturedCollection from "../../Components/HighLevelComponents/FeaturedCollection/FeaturedCollection";
import InfiniteScrollingGrid from "../../Components/HighLevelComponents/InfiniteScrollingGrid/InfiniteScrollingGrid";
import PageWrapper from "../../Components/Layout/PageWrapper/PageWrapper";
import Spacer from "../../Components/Layout/Spacer/Spacer";
import VerticalFlexContainer from "../../Components/Layout/VerticalFlexContainer/VerticalFlexContainer";
import MainText from "../../Components/Text/MainText/MainText";
import { GET_PROJECTS } from "../../graphql";
import { IProject } from "../../types/Project";
import styles from "./ProjectRarities.module.css";

interface ProjectQueryData {
  getProjects: IProject[];
}

function useProjectsQueries(isRarityEnabled?: boolean) {
  const { loading, error, data } = useQuery<ProjectQueryData>(GET_PROJECTS, {
    variables: {
      isRarityEnabled: isRarityEnabled,
    },
  });
  return { loading, error, data };
}

export default function ProjectRarities() {
  const { data } = useProjectsQueries(true);
  const renderCards = !!data;
  const navigate = useNavigate();

  return (
    <PageWrapper>
      <FeaturedCollection collectionId={15} />
      <VerticalFlexContainer paddingTop="5%" paddingBottom="10%" width="100%">
        <MainText
          fontWeight={700}
          fontSize="30px"
          textAlign="center"
          color="#FFFFFF"
        >
          List of Collections
        </MainText>
        <Spacer height="24px" />
        {renderCards && (
          <InfiniteScrollingGrid>
            {data.getProjects.map((project, i) => (
              <VerticalFlexContainer
                className={styles.rarityBaseContainer}
                onClick={() => {
                  navigate(`${project.id}`);
                }}
              >
                <img style={{ width: "100%" }} src={project.image_url}></img>
                <VerticalFlexContainer padding="10px" paddingLeft="18px">
                  <MainText fontWeight={800}>{project.name}</MainText>
                </VerticalFlexContainer>
              </VerticalFlexContainer>
            ))}
          </InfiniteScrollingGrid>
        )}
      </VerticalFlexContainer>
    </PageWrapper>
  );
}
