import React from "react";
import PageWrapper from "../Components/Layout/PageWrapper/PageWrapper";
import VerticalFlexContainer from "../Components/Layout/VerticalFlexContainer/VerticalFlexContainer";
import MainText from "../Components/Text/MainText/MainText";

export default function NotFound() {
  return (
    <PageWrapper>
      <VerticalFlexContainer
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <MainText fontWeight={800} color="white">
          NOT FOUND
        </MainText>
        <MainText color="white">
          But look at this cute zombie cheese loving doctor alpaca
        </MainText>
        <img
          style={{
            width: "50%",
            maxWidth: "400px",
          }}
          src="https://www.arweave.net/nMgwbiry18SLSVXa7j7WxpzHviW5NhcBl584vJeBu5A?ext=png"
        />
      </VerticalFlexContainer>
    </PageWrapper>
  );
}
