import { RiLiveLine } from "react-icons/ri";

import React from "react";
import StatusBadge from "../StatusBadge/StatusBadge";

const LiveStatusBadge: React.FC = ({ children, ...props }) => {
  return (
    <StatusBadge
      mainColour={"#16C172"}
      secondaryColour={"#EFF9F0"}
      icon={RiLiveLine}
      text={"LIVE"}
    />
  );
};

export default LiveStatusBadge;
