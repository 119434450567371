import React from "react";

export default function Spacer({
  width,
  height,
  marginLeft,
}: {
  width?: string;
  height?: string;
  marginLeft?: string;
}) {
  return (
    <div
      style={{
        width,
        height,
        marginLeft,
      }}
    />
  );
}
