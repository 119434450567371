import React from "react";
import HorizontalFlexContainer from "../../Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import Spacer from "../../Layout/Spacer/Spacer";
import MainText from "../../Text/MainText/MainText";
import SolanaLogo from "../SolanaLogo/SolanaLogo";

export default function NFTPrice({ price }: { price: number }) {
  return (
    <HorizontalFlexContainer alignItems="center">
      <MainText fontSize="12px">{price}</MainText>
      <Spacer width="4px" />
      <SolanaLogo size={12} />
    </HorizontalFlexContainer>
  );
}
