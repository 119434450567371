import { gql } from "@apollo/client";

export const GET_PROJECT = gql`
  query ($getProjectId: Int!) {
    getProject(id: $getProjectId) {
      project {
        id
        name
        owner_id
        long_description
        short_description
        image_url
        candy_machine_id
        mint_date
        mint_price
        twitter
        discord
        website
        is_rarity_enabled
        number_of_items
        wl_token_id
      }
      owner {
        name
      }
    }
  }
`;

export const GET_PROJECTS = gql`
  query ($isRarityEnabled: Boolean) {
    getProjects(is_rarity_enabled: $isRarityEnabled) {
      id
      short_description
      name
      mint_date
      image_url
    }
  }
`;

export const GET_RARITIES = gql`
  query (
    $projectId: Int!
    $start: Int
    $limit: Int
    $searchString: String
    $isListed: Boolean
  ) {
    getRarities(
      project_id: $projectId
      start: $start
      limit: $limit
      search_string: $searchString
      is_listed: $isListed
    ) {
      rarity_rank
      mint_id
      name
      image_url
      current_price
      latest_sale
      last_updated
      rarity_class
    }
  }
`;

export const GET_HOT_DEALS = gql`
  query ($projectId: Int!) {
    getHotDeals(projectId: $projectId) {
      rarity_rank
      mint_id
      name
      image_url
      current_price
      latest_sale
      last_updated
      rarity_class
    }
  }
`;
