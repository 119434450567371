import { MdOutlineAccessTime } from "react-icons/md";

import React from "react";
import StatusBadge from "../StatusBadge/StatusBadge";

const UpcomingStatusBadge: React.FC = ({ children, ...props }) => {
  return (
    <StatusBadge
      mainColour={"#E09F3E"}
      secondaryColour={"#F2EBCA"}
      icon={MdOutlineAccessTime}
      text={"UPCOMING"}
    />
  );
};

export default UpcomingStatusBadge;
