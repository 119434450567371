import "./App.css";
import { useMemo } from "react";

import ProjectHome from "./Routes/ProjectHome/ProjectHome";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { createTheme, ThemeProvider } from "@material-ui/core";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import Home from "./Routes/Home";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import NavigationBar from "./Components/HighLevelComponents/Navbar/Navbar";
import LandingPage from "./Routes/LandingPage/LandingPage";
import ProjectRarityHome from "./Routes/ProjectRarityHome/ProjectRarityHome";
import ProjectRarities from "./Routes/ProjectRarities/ProjectRarities";
import NotFound from "./Routes/NotFound";

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
});

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#F1779A",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  overrides: {
    MuiButtonBase: {
      root: {
        justifyContent: "flex-start",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "white",
      },
    },
    MuiButton: {
      root: {
        textTransform: undefined,
        padding: "12px 16px",
      },
      startIcon: {
        marginRight: 8,
      },
      endIcon: {
        marginLeft: 8,
      },
    },
  },
});

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    []
  );

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect={true}>
            <WalletDialogProvider>
              <NavigationBar />
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<LandingPage />}></Route>
                  <Route path="/projects" element={<Home />} />
                  <Route
                    path="/projects/:projectId"
                    element={<ProjectHome connection={connection} />}
                  />
                  <Route
                    path="/rarity-projects"
                    element={<ProjectRarities />}
                  />
                  <Route
                    path="/rarity-projects/:projectId"
                    element={<ProjectRarityHome connection={connection} />}
                  />
                  <Route path="/not-found" element={<NotFound />} />
                </Routes>
              </BrowserRouter>
            </WalletDialogProvider>
          </WalletProvider>
        </ConnectionProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
