import { useNavigate } from "react-router-dom";
import HorizontalFlexContainer from "../../Components/Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import PageWrapper from "../../Components/Layout/PageWrapper/PageWrapper";
import Spacer from "../../Components/Layout/Spacer/Spacer";
import VerticalFlexContainer from "../../Components/Layout/VerticalFlexContainer/VerticalFlexContainer";
import MainText from "../../Components/Text/MainText/MainText";
import styles from "./LandingPage.module.css";

function ExploreButton() {
  let navigate = useNavigate();

  return (
    <HorizontalFlexContainer
      borderRadius="30px"
      width="250px"
      height="60px"
      alignItems="center"
      backgroundColor="#F1779A"
      justifyContent="center"
      onClick={() => {
        navigate(`/projects`);
      }}
    >
      <MainText
        color="white"
        width="auto"
        fontWeight={700}
        fontSize="24px"
        textShadow="1px 1px 3px rgba(1,1,1,0.5)"
      >
        Launch App
      </MainText>
    </HorizontalFlexContainer>
  );
}

export default function LandingPage() {
  const navigate = useNavigate();
  return (
    <PageWrapper>
      <HorizontalFlexContainer
        minHeight="85vh"
        alignItems="center"
        flexWrap="wrap"
        paddingLeft="10%"
        paddingRight="10%"
      >
        <VerticalFlexContainer
          flex={1}
          alignItems="center"
          marginTop="12px"
          marginBottom="12px"
        >
          <VerticalFlexContainer minWidth="300px" maxWidth="500px">
            <MainText fontWeight={900} fontSize="32px" color="white">
              Two-in-one
            </MainText>
            <MainText fontWeight={700} fontSize="30px" color="white">
              Explore and Mint
            </MainText>
            <MainText fontWeight={700} fontSize="30px" color="white">
              Through MOONGATE.
            </MainText>
            <MainText color="white">
              The first community-owned launchpad featuring upcoming NFT
              projects on the Solana blockchain and their minting homes.
            </MainText>
            <Spacer height="20px" />
            <ExploreButton />
          </VerticalFlexContainer>
        </VerticalFlexContainer>
        <VerticalFlexContainer
          flex={1}
          justifyContent="center"
          // alignItems="center"
          marginTop="12px"
          marginBottom="12px"
        >
          <VerticalFlexContainer
            backgroundColor="#eff9f0"
            borderRadius="20px"
            overflowY={"hidden"}
            alignItems="center"
            minWidth="300px"
            maxWidth="500px"
          >
            <VerticalFlexContainer>
              <img
                width="100%"
                src="https://res.cloudinary.com/dtw7pook8/image/upload/v1642358325/Moon%20gate%20images/222_u0fywg.png"
              ></img>
            </VerticalFlexContainer>
            <HorizontalFlexContainer padding="8px">
              <button
                className={styles.exploreButton}
                onClick={(e) => {
                  navigate(`/rarity-projects/15`);
                }}
              >
                <MainText fontWeight={600}>Explore</MainText>
              </button>
            </HorizontalFlexContainer>
          </VerticalFlexContainer>
          <VerticalFlexContainer paddingLeft="14px">
            <Spacer height="12px"></Spacer>
            <MainText color="white" fontSize="16px">
              INTRODUCING OUR HOUSE COLLECTION ─
            </MainText>
            <MainText color="white" fontWeight={800} fontSize="16px">
              Cute Alpaca Club
            </MainText>
          </VerticalFlexContainer>
        </VerticalFlexContainer>
      </HorizontalFlexContainer>
    </PageWrapper>
  );
}
