import React from "react";
import MainText from "../../Text/MainText/MainText";
import styles from "./RarityClassBadge.module.css";

interface RarityClassPropsInterface {
  rarityClass: number;
}

const rarityClassesUIProperties = [
  {
    displayText: "MYTHIC",
    className: styles.mythic,
  },
  {
    displayText: "LEGENDARY",
    className: styles.legendary,
  },
  {
    displayText: "EPIC",
    className: styles.epic,
  },
  {
    displayText: "RARE",
    className: styles.rare,
  },
  {
    displayText: "UNCOMMON",
    className: styles.uncommon,
  },
  {
    displayText: "COMMON",
    className: styles.common,
  },
];

export default function RarityClassBadge(props: RarityClassPropsInterface) {
  return (
    <div
      className={`${styles.container} ${
        rarityClassesUIProperties[props.rarityClass].className
      }`}
    >
      <MainText color="rgba(240,240,240,0.9)" fontSize="10px" fontWeight={900}>
        {rarityClassesUIProperties[props.rarityClass].displayText}
      </MainText>
    </div>
  );
}
