import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { PublicKey } from "@solana/web3.js";

import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";

import styles from "./ProjectHome.module.css";

import {
  CandyMachineAccount,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
} from "../../candy-machine";

import { useParams } from "react-router-dom";
import HorizontalFlexContainer from "../../Components/Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import MainText from "../../Components/Text/MainText/MainText";
import VerticalFlexContainer from "../../Components/Layout/VerticalFlexContainer/VerticalFlexContainer";
import Spacer from "../../Components/Layout/Spacer/Spacer";
import LiveStatusBadge from "../../Components/HighLevelComponents/LiveStatusBadge/LiveStatusBadge";
import UpcomingStatusBadge from "../../Components/HighLevelComponents/UpcomingStatusBadge/UpcomingStatusBadge";
import SolanaLogo from "../../Components/HighLevelComponents/SolanaLogo/SolanaLogo";
import PageWrapper from "../../Components/Layout/PageWrapper/PageWrapper";
import { useProjectQuery } from "../../utils/hooks";
import Socials from "../../Components/HighLevelComponents/Socials/Socials";

export interface HomeProps {
  candyMachineId?: string;
  startDate?: number;
}

const useCandyMachineState = ({ candyMachineId, startDate }: HomeProps) => {
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
  const { connection } = useConnection();
  const wallet = useAnchorWallet();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!candyMachineId) return;

      const candyMachine = await getCandyMachineState(
        wallet as anchor.Wallet,
        new PublicKey(candyMachineId),
        connection
      );

      setItemsRedeemed(candyMachine.state.itemsRedeemed);
      setItemsAvailable(candyMachine.state.itemsAvailable);
      setIsSoldOut(candyMachine.state.itemsRemaining === 0);
      setCandyMachine(candyMachine);
      refreshIsActive();
    })();
  };

  const refreshIsActive = () => {
    console.log("REFRESHING IS ACTIVE!");
    if (startDate) {
      const currentDateTime = new Date().getTime() / 1000;
      if (startDate < currentDateTime && !isSoldOut) {
        setIsActive(true);
      }

      if (candyMachineId) {
        setIsActive(true);
      }
    }
  };
  useEffect(refreshCandyMachineState, [candyMachineId, wallet, connection]);

  useEffect(() => {
    refreshIsActive();
  }, [startDate]);

  return {
    isActive,
    isSoldOut,
    setIsSoldOut,
    candyMachine,
    refreshCandyMachineState,
    refreshIsActive,
    itemsRedeemed,
    itemsAvailable,
  };
};

export default function ProjectHome({
  connection,
}: {
  connection: anchor.web3.Connection;
}) {
  let params = useParams();

  const { loading, error, data } = useProjectQuery(parseInt(params.projectId!));
  const startDate = data?.getProject.project.mint_date;

  const {
    isActive,
    isSoldOut,
    itemsRedeemed,
    itemsAvailable,
    setIsSoldOut,
    candyMachine,
    refreshCandyMachineState,
    refreshIsActive,
  } = useCandyMachineState({
    candyMachineId: data?.getProject.project.candy_machine_id,
    startDate,
  });

  const [isMinting, setIsMinting] = useState(false);
  const wallet = useAnchorWallet();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const onMint = async () => {
    const owner = data?.getProject.owner;
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program && owner) {
        const mintTxId = (
          await mintOneToken(candyMachine, wallet.publicKey)
        )[0];

        let status: any = { err: true };
        if (mintTxId) {
          status = await awaitTransactionSignatureConfirmation(
            mintTxId,
            30000,
            connection,
            true
          );
        }

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await connection.getBalance(wallet.publicKey);
        // setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      setTimeout(() => {
        refreshCandyMachineState();
      }, 15000);
    }
  };

  if (data) {
    const project = data.getProject.project;
    const owner = data.getProject.owner!;
    return (
      <PageWrapper>
        <HorizontalFlexContainer
          height="100%"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          paddingLeft="5%"
          paddingRight="5%"
          flexWrap="wrap"
        >
          <HorizontalFlexContainer
            flex={1}
            minWidth="360px"
            maxWidth="500px"
            justifyContent="center"
            alignItems="center"
            marginTop="10px"
            marginLeft="10px"
            marginRight="10px"
            marginBottom="10px"
          >
            <img
              src={project.image_url}
              width="100%"
              style={{
                borderRadius: "10px",
              }}
            />
          </HorizontalFlexContainer>
          <VerticalFlexContainer
            flex={1}
            backgroundColor="#FFFFFF"
            padding="40px"
            borderRadius="20px"
            minWidth="360px"
            maxWidth="500px"
            marginTop="10px"
            marginLeft="10px"
            marginRight="10px"
            marginBottom="10px"
          >
            <MainText fontSize="30px" fontWeight={700}>
              {project.name}
            </MainText>
            <HorizontalFlexContainer>
              <MainText fontSize="20px" width="auto">
                By {owner.name}
              </MainText>
              <Spacer width="10px" />
              <Socials
                discord={project.discord}
                twitter={project.twitter}
                website={project.website}
              />
            </HorizontalFlexContainer>

            <Spacer height="10px" />
            <HorizontalFlexContainer
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              {isActive ? <LiveStatusBadge /> : <UpcomingStatusBadge />}
              {project.mint_price && (
                <HorizontalFlexContainer>
                  <SolanaLogo />
                  <Spacer width="6px" />
                  <MainText width="auto" fontWeight={700} fontSize="18px">
                    {project.mint_price!}
                  </MainText>
                </HorizontalFlexContainer>
              )}
            </HorizontalFlexContainer>

            <Spacer height="10px" />
            <MainText>{project.long_description}</MainText>
            <Spacer height="10px" />
            {isSoldOut ? (
              <HorizontalFlexContainer
                width="100%"
                height="40px"
                justifyContent="center"
                backgroundColor="#E4E4E4"
                alignItems="center"
                borderRadius="12px"
              >
                <MainText width="auto" color="#8B8B8B">
                  Sold Out!
                </MainText>
              </HorizontalFlexContainer>
            ) : startDate && isActive && candyMachine?.state ? (
              wallet ? (
                <HorizontalFlexContainer
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <MainText width="auto" fontWeight={600} fontSize="16px">
                    {itemsRedeemed}/{itemsAvailable}
                  </MainText>
                  <Spacer width="10px" />
                  <button onClick={onMint} className={styles.mintButton}>
                    <MainText fontWeight={700} color="white">
                      MINT
                    </MainText>
                  </button>
                </HorizontalFlexContainer>
              ) : (
                <HorizontalFlexContainer
                  width="100%"
                  height="40px"
                  justifyContent="center"
                  backgroundColor="#E4E4E4"
                  alignItems="center"
                  borderRadius="12px"
                >
                  <MainText width="auto" color="#8B8B8B">
                    Connect your wallet to mint!
                  </MainText>
                </HorizontalFlexContainer>
              )
            ) : (
              <Countdown
                date={startDate! * 1000}
                onMount={({ completed }) => completed && refreshIsActive()}
                onComplete={() => {
                  refreshCandyMachineState();
                }}
                renderer={renderCounter}
              />
            )}
          </VerticalFlexContainer>
        </HorizontalFlexContainer>
        <Snackbar
          open={alertState.open}
          autoHideDuration={6000}
          onClose={() => setAlertState({ ...alertState, open: false })}
        >
          <Alert
            onClose={() => setAlertState({ ...alertState, open: false })}
            severity={alertState.severity}
          >
            {alertState.message}
          </Alert>
        </Snackbar>
      </PageWrapper>
    );
  }
  return <></>;
}

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <HorizontalFlexContainer
      width="100%"
      height="auto"
      padding="6px"
      justifyContent="center"
      backgroundColor="#E4E4E4"
      alignItems="center"
      borderRadius="12px"
    >
      <MainText width="auto" color="#8B8B8B" textAlign="center">
        {days && `${days} days, `}
        {hours && `${hours} hours, `}
        {minutes && `${minutes} minutes, `}
        {seconds} seconds
      </MainText>
    </HorizontalFlexContainer>
  );
};
