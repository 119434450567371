import React from "react";
import { IRarity } from "../../../types/Rarity";
import HorizontalFlexContainer from "../../Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import VerticalFlexContainer from "../../Layout/VerticalFlexContainer/VerticalFlexContainer";
import MainText from "../../Text/MainText/MainText";
import NFTPrice from "../NFTPrice/NFTPrice";
import RarityClassBadge from "../RarityClassBadge/RarityClassBadge";
import styles from "./SmallRarityCard.module.css";
export function SmallRarityCard({ rarity }: { rarity: IRarity }) {
  const onClick = () => {
    const url = `https://magiceden.io/item-details/${rarity.mint_id}`;
    window.open(url, "_blank");
  };

  return (
    <HorizontalFlexContainer
      width="300px"
      // height="auto"
      backgroundColor="white"
      borderRadius="8px"
      padding="6px"
      margin="4px"
      onClick={onClick}
      cursor="pointer"
    >
      <img src={rarity.image_url} className={styles.smallRarityImage} />
      <VerticalFlexContainer
        width="100%"
        paddingLeft="20px"
        justifyContent="space-between"
        paddingTop="8px"
        paddingBottom="8px"
      >
        <VerticalFlexContainer width="100%">
          <MainText fontWeight={700}>{rarity.name}</MainText>
          <MainText fontWeight={500} fontSize="14px">
            Rank {rarity.rarity_rank}
          </MainText>
        </VerticalFlexContainer>
        <HorizontalFlexContainer
          justifyContent="space-between"
          alignItems="center"
        >
          <RarityClassBadge rarityClass={rarity.rarity_class!} />
          <NFTPrice price={rarity.current_price!} />
        </HorizontalFlexContainer>
      </VerticalFlexContainer>
    </HorizontalFlexContainer>
  );
}
