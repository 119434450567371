import { useQuery } from "@apollo/client";
import React from "react";
import CollectionCard from "../Components/HighLevelComponents/CollectionCard/CollectionCard";
import FeaturedCollection from "../Components/HighLevelComponents/FeaturedCollection/FeaturedCollection";
import InfiniteScrollingGrid from "../Components/HighLevelComponents/InfiniteScrollingGrid/InfiniteScrollingGrid";
import PageWrapper from "../Components/Layout/PageWrapper/PageWrapper";
import Spacer from "../Components/Layout/Spacer/Spacer";
import VerticalFlexContainer from "../Components/Layout/VerticalFlexContainer/VerticalFlexContainer";
import MainText from "../Components/Text/MainText/MainText";
import { GET_PROJECTS } from "../graphql";
import { IProject } from "../types/Project";

interface ProjectQueryData {
  getProjects: IProject[];
}

function useProjectsQueries() {
  const { loading, error, data } = useQuery<ProjectQueryData>(GET_PROJECTS, {
    variables: {
      isRarityEnabled: false,
    },
  });
  return { loading, error, data };
}

export default function Home() {
  const { data } = useProjectsQueries();
  const renderCards = !!data;
  return (
    <PageWrapper>
      <FeaturedCollection collectionId={15} />
      <VerticalFlexContainer paddingTop="5%" paddingBottom="10%" width="100%">
        <MainText
          fontWeight={700}
          fontSize="30px"
          textAlign="center"
          color="#FFFFFF"
        >
          List of Collections
        </MainText>
        <Spacer height="24px" />
        {renderCards && (
          <InfiniteScrollingGrid>
            {data.getProjects.map((a, i) => (
              <>
                <CollectionCard
                  projectId={a.id}
                  name={a.name}
                  shortDescription={a.short_description}
                  mintDate={a.mint_date}
                  imageSource={a.image_url}
                  key={i}
                />
              </>
            ))}
          </InfiniteScrollingGrid>
        )}
      </VerticalFlexContainer>
    </PageWrapper>
  );
}
