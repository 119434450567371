import React from "react";
import HorizontalFlexContainer from "../../Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import Spacer from "../../Layout/Spacer/Spacer";
import VerticalFlexContainer from "../../Layout/VerticalFlexContainer/VerticalFlexContainer";
import MainText from "../../Text/MainText/MainText";
import styles from "./StatusBadge.module.css";
import { IconType } from "react-icons/lib";

interface StatusBadgeProps {
  mainColour: string;
  secondaryColour: string;
  icon: IconType;
  text: string;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ children, ...props }) => {
  return (
    <HorizontalFlexContainer
      borderRadius="8px"
      paddingLeft="8px"
      paddingRight="8px"
      height="25px"
      backgroundColor={props.secondaryColour}
      border={`2px solid ${props.mainColour}`}
      alignItems="center"
    >
      <HorizontalFlexContainer height="100%" alignItems="center">
        {props.icon({
          color: props.mainColour,
          size: "18px",
        })}
      </HorizontalFlexContainer>
      <Spacer width="6px" />
      <MainText color={props.mainColour} fontWeight={600} fontSize="12px">
        {props.text}
      </MainText>
    </HorizontalFlexContainer>
  );
};

export default StatusBadge;
