import HorizontalFlexContainer from "../../Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import Spacer from "../../Layout/Spacer/Spacer";

export default function Socials({
  discord,
  twitter,
  website,
  color,
}: {
  discord?: string;
  twitter?: string;
  website?: string;
  color: string;
}) {
  const iconSize = 20;
  return (
    <HorizontalFlexContainer alignItems="center">
      {discord && (
        <>
          <a href={discord} target="_blank">
            <FaDiscord color={color} size={iconSize} />
          </a>
          <Spacer width="10px" />
        </>
      )}
      {twitter && (
        <>
          <a href={twitter} target="_blank">
            <FaTwitter color={color} size={iconSize} />
          </a>
          <Spacer width="10px" />
        </>
      )}
      {website && (
        <>
          <CgWebsite color={color} size={iconSize} />
        </>
      )}
    </HorizontalFlexContainer>
  );
}

Socials.defaultProps = {
  color: "#363537",
};
