import React from "react";
import { IProject } from "../../../types/Project";
import { useProjectQuery } from "../../../utils/hooks";
import HorizontalFlexContainer from "../../Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import VerticalFlexContainer from "../../Layout/VerticalFlexContainer/VerticalFlexContainer";
import MainText from "../../Text/MainText/MainText";
import Socials from "../Socials/Socials";
import styles from "./FeaturedCollection.module.css";
import { IoRocketOutline } from "react-icons/io5";
import Spacer from "../../Layout/Spacer/Spacer";
import { useNavigate } from "react-router-dom";

function ExploreButton({ projectId }: { projectId: number }) {
  let navigate = useNavigate();

  return (
    <HorizontalFlexContainer
      border="2px solid #F8F4E3"
      borderRadius="18px"
      width="max-content"
      alignItems="center"
      justifyContent="center"
      paddingTop="8px"
      paddingBottom="8px"
      paddingLeft="16px"
      paddingRight="16px"
      className={styles.exploreButton}
      onClick={() => {
        navigate(`/rarity-projects/${projectId}`);
      }}
    >
      <IoRocketOutline color="#F8F4E3" size={24} />
      <Spacer width="16px" />
      <MainText
        color="#F8F4E3"
        width="auto"
        fontWeight={700}
        fontSize="14px"
        textShadow="1px 1px 1px #050609"
      >
        Explore collection
      </MainText>
    </HorizontalFlexContainer>
  );
}

function RenderContent({ project }: { project: IProject }) {
  return (
    <VerticalFlexContainer className={styles.featuredMainContainer}>
      <img
        className={styles.backgroundImage}
        src="https://res.cloudinary.com/dtw7pook8/image/upload/v1641655512/Moon%20gate%20images/collage_f8h0k9.png"
      />
      <div className={styles.shadow}></div>
      <VerticalFlexContainer className={styles.mainContent}>
        <MainText
          fontWeight={900}
          fontSize="3em"
          color="#F8F4E3"
          textShadow="1px 1px 1px #050609"
        >
          {project.name}
        </MainText>
        <MainText
          fontWeight={600}
          fontSize="1em"
          color="#F8F4E3"
          textShadow="1px 1px 1px #050609"
        >
          {project.short_description}
        </MainText>
        <Socials
          twitter={project.twitter}
          discord={project.discord}
          website={project.website}
          color="#F8F4E3"
        />
        <Spacer height="30px" />
        <ExploreButton projectId={project.id} />
      </VerticalFlexContainer>
    </VerticalFlexContainer>
  );
}

export default function FeaturedCollection({
  collectionId,
}: {
  collectionId: number;
}) {
  const { loading, error, data } = useProjectQuery(collectionId);
  if (data?.getProject.project) {
    return <RenderContent project={data.getProject.project} />;
  }

  return <HorizontalFlexContainer className={styles.featuredMainContainer} />;
}
