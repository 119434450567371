import React from "react";
import { useNavigate } from "react-router-dom";
import HorizontalFlexContainer from "../../Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import Spacer from "../../Layout/Spacer/Spacer";
import VerticalFlexContainer from "../../Layout/VerticalFlexContainer/VerticalFlexContainer";
import MainText from "../../Text/MainText/MainText";
import LiveStatusBadge from "../LiveStatusBadge/LiveStatusBadge";
import UpcomingStatusBadge from "../UpcomingStatusBadge/UpcomingStatusBadge";
import styles from "./CollectionCard.module.css";

interface CollectionCard {
  imageSource: string;
  // status: string;
  projectId: number;
  name: string;
  shortDescription: string;
  mintDate?: number;
  // mintPrice: number;
}

interface FooterProps {
  projectId: number;
  name: string;
  shortDescription: string;
}

const Image: React.FC<{ imageSource: string }> = ({ imageSource }) => {
  return (
    <img
      src={imageSource}
      className={styles.image}
      referrerPolicy="no-referrer"
      alt="Project image"
    />
  );
};

const isToday = (someDate: Date) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};
function toDateTime(secs: number) {
  var t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
}

function formatDateNumber(n: number) {
  return n < 9 ? `0${n}` : n;
}

function formatDateTime(secs: number) {
  const mintDate = toDateTime(secs);
  if (!isToday(mintDate)) {
    return `${formatDateNumber(mintDate.getDate())}/${formatDateNumber(
      mintDate.getMonth() + 1
    )}/${mintDate.getFullYear()}`;
  } else {
    return `${formatDateNumber(mintDate.getHours())}:${formatDateNumber(
      mintDate.getMinutes()
    )}`;
  }
}

const Header: React.FC<{ mintDate?: number }> = ({ mintDate }) => {
  const currentTime = new Date().getTime() / 1000;
  return (
    <HorizontalFlexContainer
      backgroundColor="#EFF9F0"
      height="60px"
      paddingLeft="20px"
      paddingRight="20px"
      alignItems="center"
      justifyContent="space-between"
    >
      <HorizontalFlexContainer>
        {!!mintDate && currentTime > mintDate ? (
          <LiveStatusBadge />
        ) : (
          <UpcomingStatusBadge />
        )}
      </HorizontalFlexContainer>
      <HorizontalFlexContainer>
        {!!mintDate && mintDate > currentTime && (
          <MainText fontWeight={500} fontSize="13px">
            {formatDateTime(mintDate)}
          </MainText>
        )}
      </HorizontalFlexContainer>
    </HorizontalFlexContainer>
  );
};

const Footer: React.FC<FooterProps> = (props) => {
  let navigate = useNavigate();
  return (
    <VerticalFlexContainer>
      <VerticalFlexContainer justifyContent="center" alignItems="center">
        <Spacer height="18px" />
        <MainText
          color="#2E282A"
          fontWeight={700}
          fontSize="20px"
          textAlign={"center"}
        >
          {props.name}
        </MainText>
        <Spacer height="6px" />
        <MainText
          color="#2E282A"
          width="60%"
          textAlign="center"
          fontSize="14px"
        >
          {props.shortDescription}
        </MainText>
        <Spacer height="12px" />
        <button
          className={styles.exploreButton}
          onClick={(e) => {
            navigate(`${props.projectId}`);
          }}
        >
          <MainText fontWeight={600}>Explore</MainText>
        </button>
        <Spacer height="18px" />
      </VerticalFlexContainer>
    </VerticalFlexContainer>
  );
};

const CollectionCard: React.FC<CollectionCard> = ({ children, ...props }) => {
  return (
    <VerticalFlexContainer className={styles.baseContainer}>
      <Header mintDate={props.mintDate} />
      <Image imageSource={props.imageSource}></Image>
      <Footer
        name={props.name}
        projectId={props.projectId}
        shortDescription={props.shortDescription}
      ></Footer>
    </VerticalFlexContainer>
  );
};

export default CollectionCard;
