import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import { Container, Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { shortenAddress } from "../../../candy-machine";
import HorizontalFlexContainer from "../../Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import Spacer from "../../Layout/Spacer/Spacer";
import MainText from "../../Text/MainText/MainText";
import MoongateLogo from "../MoongateLogo/MoongateLogo";

export default function NavigationBar() {
  const walletHandler = useWallet();
  const wallet = useAnchorWallet();

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      style={{
        backgroundColor: "#050609",
      }}
      sticky="top"
    >
      <Container>
        <Navbar.Brand href="/projects">
          <HorizontalFlexContainer alignItems="center">
            <MoongateLogo />
            <Spacer width="12px" />
            <MainText color="white" width="auto" fontWeight={600}>
              MOONGATE
            </MainText>
          </HorizontalFlexContainer>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" />
          <Nav>
            <Nav.Link
              href="/projects"
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <MainText color="white" width="auto" fontWeight={600}>
                Launchpad
              </MainText>
              <Spacer width="20px" />
            </Nav.Link>
            <Nav.Link
              href="/rarity-projects"
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <MainText color="white" width="auto" fontWeight={600}>
                Rarities
              </MainText>
              <Spacer width="20px" />
            </Nav.Link>
            <Nav.Link
              href="https://acrobat.adobe.com/link/track?uri=urn:aaid:scds:US:13e6b6f8-1c0a-3825-990a-57187249b8c5#pageNum=1"
              target="_blank"
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <MainText color="white" width="auto" fontWeight={600}>
                About
              </MainText>
              <Spacer width="20px" />
            </Nav.Link>
            <Nav.Link
              href="https://documentcloud.adobe.com/link/track?uri=urn:aaid:scds:US:944c561b-573e-3980-835d-517a8c79a600"
              target="_blank"
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <MainText color="white" width="auto" fontWeight={600}>
                Whitepaper
              </MainText>
              <Spacer width="20px" />
            </Nav.Link>
            {!wallet ? (
              <WalletDialogButton
                style={{
                  backgroundColor: "#F1779A",
                  height: "40px",
                  borderRadius: "20px",
                }}
              >
                <MainText color="white" fontWeight={600}>
                  Connect
                </MainText>
              </WalletDialogButton>
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    backgroundColor: "#F1779A",
                    height: "40px",
                    borderRadius: "20px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    borderStyle: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MainText color="white" fontWeight={700} width="auto">
                    {shortenAddress(wallet.publicKey!.toString())}
                  </MainText>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      walletHandler.disconnect();
                    }}
                  >
                    Disconnect
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
